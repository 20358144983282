declare var $$epiforms: any;

class Progressbar {
    el: Element;	
    step: number;
    maxSteps: number;
	progressBar: Element | null;
	navigationBar: Element | null;
	submitButton: Element | null;
	constructor(el: Element) {
		this.el = el;
		this.step = 0;
		this.maxSteps = el.querySelectorAll(".progress-bar__circle").length;
		this.progressBar = el.querySelector(".progress-bar");
		this.navigationBar = el.querySelector(".Form__NavigationBar");
		this.submitButton = this.el.querySelector(".FormSubmitButton");

		this.nextStepEvent();
		this.previousStepEvent();

		this.setSubmitButtonLocation();
	}

	nextStepEvent() {
		$$epiforms(this.el).on("formsNavigationNextStep", () => {
			this.step += 1;

			this.toggleSubmitButtonVisibility();

			this.progressBar?.querySelector(`.progress-bar__circle[data-set="${this.step}"]`)?.classList.add("progress-bar__circle--active");
			this.progressBar?.querySelector(`.progress-bar__line[data-set="${this.step}"]`)?.classList.add("progress-bar__line--active");

			this.progressBar?.querySelector(`.progress-bar__circle[data-set="${this.step - 1}"]`)?.classList.remove("progress-bar__circle--current")
			this.progressBar?.querySelector(`.progress-bar__circle[data-set="${this.step}"]`)?.classList.add("progress-bar__circle--current")
		});
	}

	previousStepEvent() {
		$$epiforms(this.el).on("formsNavigationPrevStep", () => {
			this.progressBar?.querySelector(`.progress-bar__circle[data-set="${this.step}"]`)?.classList.remove("progress-bar__circle--active");
			this.progressBar?.querySelector(`.progress-bar__line[data-set="${this.step}"]`)?.classList.remove("progress-bar__line--active");

			this.progressBar?.querySelector(`.progress-bar__circle[data-set="${this.step - 1}"]`)?.classList.add("progress-bar__circle--current")
			this.progressBar?.querySelector(`.progress-bar__circle[data-set="${this.step}"]`)?.classList.remove("progress-bar__circle--current")

			this.step -= 1;

			this.toggleSubmitButtonVisibility();
		});
	}

	toggleSubmitButtonVisibility() {
		if (this.step + 1 == this.maxSteps) {
			this.submitButton?.removeAttribute("disabled");
		} else {
			this.submitButton?.setAttribute("disabled", "");
		}
	}

	setSubmitButtonLocation() {
		if (this.submitButton !== null) {
			this.navigationBar?.append(this.submitButton);

			this.toggleSubmitButtonVisibility();
		}
	}
}


document.querySelectorAll(".EPiServerForms").forEach(el => {
	new Progressbar(el);
})
