import Handlebars from 'handlebars';

document.addEventListener('DOMContentLoaded', () => {
	if (document.querySelector("#resourceList") != null) {

		const resultHtmlSource = require("./index.html").default;
		const resultHtmlTemplate = Handlebars.compile(resultHtmlSource);
		const resourceList = document.getElementById('resourceList');
		const loadMoreButton = document.querySelector("[data-load-more-type='ResourceList']") as HTMLButtonElement;
		const scssGuid = resourceList?.attributes[0]?.name ?? ""
		// Initial variables
		let currentPageNumber = 2;
		let currentFilter = "";

		function initializeRawHtml(rawHtml: string): Element {
			const surrogate = document.createElement("div");
			surrogate.innerHTML = rawHtml || "";

			const element = surrogate.children.item(0);

			if (!element)
				throw Error("There was a problem initializing the html.");

			return element;
		}

		// Function to show loading state
		function showLoading() {
			if (resourceList) {
				// Show loading message or spinner
				const loadingMessage = document.createElement('p');
				loadingMessage.textContent = 'Loading...';
				resourceList.appendChild(loadingMessage);
			}
		}

		// Function to hide loading state
		function hideLoading() {
			if (resourceList) {
				// Remove loading message or spinner
				const loadingMessage = resourceList.querySelector('p');

				if (loadingMessage) {
					resourceList.removeChild(loadingMessage);
				}
			}
		}

		// Function to fetch resources
		async function fetchResourcesAsync(reset = false): Promise<void> {
			// Show loading state
			showLoading();

			if (reset) {
				currentPageNumber = 1;

				if (resourceList)
					resourceList.innerText = "";
			}

			try {
				// Fetch data from the server
				const response = await fetch(`/api/Resources?pageNumber=${currentPageNumber++}&filter=${currentFilter}&scssGuid=${scssGuid}`);

				if (!response.ok) {
					throw new Error('Network response was not ok');
				}

				const data = await response.json();

				if (data.length > 0) {
					// Append data to the resource list
					data.forEach((item: any) => {
						const rowHtml = resultHtmlTemplate(item);
						const row = initializeRawHtml(rowHtml);

						resourceList?.append(row);
					});
				}
				else if (loadMoreButton) {
					loadMoreButton.style.display = 'none';
				}
			}
			catch (error) {
				console.error('Error fetching resources:', error);
				alert('Error fetching resources');
			}
			finally {
				hideLoading();
			}
		}

		// Filter buttons functionality
		const filterButtons = document.querySelectorAll("[data-filter-type='ResourceList']");

		filterButtons.forEach(button => {
			button.addEventListener('click', async () => {
				const htmlButton = button as HTMLButtonElement;
				currentFilter = htmlButton.dataset.category ?? "";

				if (button.classList.contains('btn-outline-secondary')) {
					button.classList.remove('btn-outline-secondary');
					button.classList.add('btn-secondary');

					const loadMoreButton = document.querySelector('.load-more');

					if (loadMoreButton) {
						(loadMoreButton as HTMLElement).style.display = 'block';
					}

					currentFilter = "";
				}
				else {
					filterButtons.forEach(btn => {
						btn.classList.remove('btn-outline-secondary')
						btn.classList.add('btn-secondary')
					});
					button.classList.remove('btn-secondary');
					button.classList.add('btn-outline-secondary');

					const loadMoreButton = document.querySelector('.load-more');

					if (loadMoreButton) {
						(loadMoreButton as HTMLElement).style.display = 'none';
					}
				}

				await fetchResourcesAsync(true);
			});
		});

		if (loadMoreButton) {
			loadMoreButton.addEventListener('click', async () => {
				const htmlButton = loadMoreButton as HTMLButtonElement;
				const strTotalItemCount = htmlButton.dataset.totalItemCount ?? "0";
				const strPageSize = htmlButton.dataset.pageSize ?? "0";

				if (strTotalItemCount) {
					const totalItemCount = parseInt(strTotalItemCount);
					const pageSize = parseInt(strPageSize);

					await fetchResourcesAsync();

					if (pageSize * currentPageNumber >= totalItemCount) {
						htmlButton.style.display = 'none';
					}
				}
			});
		}
	}
});