class Header {
	burgerMenu: any;
	menuOverlay: any;
	firstLevelMenu: NodeListOf<Element>;
	overlayFirstLevel: NodeListOf<Element>;
	overlaySecondLevelItem: NodeListOf<Element>;

	constructor(el: Element) {

		this.burgerMenu = el.querySelector(".header__logo-burger__burger");
		this.menuOverlay = el.querySelector(".header__menu-overlay");
		this.overlaySecondLevelItem = el.querySelectorAll(".header__menu-overlay__first-level__item__second-level__item");
		this.firstLevelMenu = el.querySelectorAll(".header__desktop-nav__menu-blocks > span");
		this.overlayFirstLevel = el.querySelectorAll(".header__menu-overlay__first-level__item");

		this.bindBurgerMenu();
		this.bindFirstLevelMenu();
		this.bindSecondLevelMenu();
		this.bindDesktopMenu();
	}

	bindBurgerMenu() {
		this.burgerMenu.addEventListener("click", () => {
			this.menuOverlay.classList.toggle("active");

			this.burgerMenu.classList.toggle("active");

			document.querySelector("body")?.classList.toggle("overflow-hidden");
		});
	}

	bindFirstLevelMenu() {
		this.overlayFirstLevel.forEach(firstLevel => {

			firstLevel.querySelector(".header__menu-overlay__first-level__item__link")?.addEventListener("click", (e) => {

				firstLevel.classList.toggle("active");
				this.overlayFirstLevel.forEach(el => el.classList.toggle("inactive"));
			});
		});
	}

	bindSecondLevelMenu() {
		this.overlaySecondLevelItem.forEach(secondLevel => {
				secondLevel.querySelector(".header__menu-overlay__first-level__item__second-level__item__link")?.addEventListener("click", () => {

					if (secondLevel.classList.contains("active")) {

						secondLevel.closest(".header__menu-overlay__first-level__item")?.querySelector(".header__menu-overlay__first-level__item__link")?.classList.remove("d-none");

						this.overlaySecondLevelItem.forEach(el => {
							el.classList.remove("inactive");
							el.classList.remove("active");
						});


					}
					else {
						secondLevel.closest(".header__menu-overlay__first-level__item")?.querySelector(".header__menu-overlay__first-level__item__link")?.classList.add("d-none");

						this.overlaySecondLevelItem.forEach(el => {
							el.classList.add("inactive");
							el.classList.remove("active");
						});

						secondLevel.classList.add("active");
					}
			});
		});
	}


	bindDesktopMenu() {
		this.firstLevelMenu.forEach((firstLevel, index) => {

			firstLevel.addEventListener("click", () => {

				if (firstLevel.classList.contains("active")) {
					firstLevel.classList.remove("active");
					this.menuOverlay.classList.remove("active");
					this.overlayFirstLevel[index]?.classList.remove("active");

				} else {

					this.firstLevelMenu.forEach(el => el.classList.remove("active"));
					this.overlayFirstLevel.forEach(el => el.classList.remove("active"));
					firstLevel.classList.add("active");
					this.menuOverlay.classList.add("active");
					this.overlayFirstLevel[index]?.classList.add("active");
				}
			});
		});
	}
}

document.querySelectorAll(".header").forEach(el => {
	new Header(el);
})