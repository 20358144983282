import { Splide } from "@splidejs/splide";
import { Grid } from "@splidejs/splide-extension-grid";

document.addEventListener('DOMContentLoaded', () => {
	document.querySelectorAll(".resources-container-block").forEach(block => {

		var splideDiv = block.querySelector('.splide') as HTMLElement;

		if (splideDiv != null) {
			new Splide(splideDiv, {
				grid: {
					rows: 4,
					cols: 1,
					gap: {
						row: '1rem',
					}
				},
				mediaQuery: 'min',
				breakpoints: {
					768: {
						grid: {
							rows: 2,
							cols: 2
						}
					},
					992: {
						grid: {
							rows: 1,
							cols: 3
						}
					},
					1200: {
						grid: {
							rows: 1,
							cols: 4
						}
					}
				}
			}).mount({ Grid });
		}
	})
});