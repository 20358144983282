document.addEventListener('DOMContentLoaded', function ()
{
	const loadMoreButtons = document.querySelectorAll("[data-load-more-type=FAQContainerBlock");
	const pageNumberMap = new Map<Element, number>();

	loadMoreButtons.forEach(button =>
	{
		pageNumberMap.set(button, 2);

		button.addEventListener('click', async () =>
		{
			const htmlButton = button as HTMLButtonElement;

			try
			{
				let pageNumber = pageNumberMap.get(button);

				if (!pageNumber)
					throw Error("The page number cannot be found.");

				htmlButton.disabled = true;

				const cssGuid = htmlButton.attributes[0]?.name ?? "";
				const containerId = htmlButton.dataset.containerId;
				const blockGuid = htmlButton.dataset.blockGuid;
				const pageSize = htmlButton.dataset.pageSize;
				const totalItemCount = htmlButton.dataset.totalItemCount;
				const accordion = document.getElementById('accordion-' + containerId);

				if (accordion)
				{
					const requestUrl = `/api/faq/loaditems?containerId=${blockGuid}&pageNumber=${pageNumber}`

					const response = await fetch(requestUrl);

					if (!response.ok)
					{
						alert("There was an error loading the FAQ items.");
						return;
					}

					const faqItems = await response.json();

					if (faqItems.length > 0)
					{
						faqItems.forEach((item: any) =>
						{
							const id = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

							var wrapper = document.createElement('div');
							wrapper.setAttribute(cssGuid, '');
							wrapper.classList.add('accordion-item');
							wrapper.innerHTML = `
								<h5 ${cssGuid} class="accordion-header" id="heading-${id}">
				                                <button ${cssGuid} class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-${id}" aria-expanded="true" aria-controls="collapse-${id}">
				                                     ${item.heading}
				                                 </button>
				                            </h5>
				                            <div ${cssGuid} class="accordion-collapse collapse" id="collapse-${id}" aria-labelledby="heading-${id}" data-bs-parent="#accordion-${containerId}">
				                            <div ${cssGuid} class="accordion-body">
									 ${item.text}
				                                 </div>
				                            </div>`;

							accordion.insertBefore(wrapper, accordion.querySelector(".load-more"));
						});
					}
					else
					{
						(button as HTMLElement).style.display = 'none'; // Hide the "Load More" button if no more FAQs to load
					}

					pageNumberMap.set(button, ++pageNumber);

					if ((pageNumber - 1) * parseInt(pageSize ?? "0") > parseInt(totalItemCount ?? "0"))
					{
						(button as HTMLElement).style.display = 'none';
					}
				}
			}
			catch (exc)
			{
				alert("There was an error loading the FAQ items.");
			}
			finally
			{
				htmlButton.disabled = false;
			}
		});
	});
});
