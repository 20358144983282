import Choices from "choices.js";

class ChoicesSelect {
	constructor(el: Element) {
		new Choices(el, {
			searchEnabled: false,
			itemSelectText: '',
		});
	}
}

document.querySelectorAll("select").forEach(el => {
	new ChoicesSelect(el);
});
